import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAppSelector } from '../../state/store';
import {
  getOverviewStartDate,
  getOverviewEndDate,
} from '../../state/localSettings/selectors';
import { tryAdobeLogPageView } from '../../utils/adobeAnalytics';

const SideMenu = ({ staffData } : any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { showDashboardPage } = useFlags();

  const overviewStartDate = useAppSelector(getOverviewStartDate);
  const overviewEndDate = useAppSelector(getOverviewEndDate);

  return (
    <sss-navbar-side-menu culture={staffData?.language || navigator?.language}>
      { showDashboardPage &&
        <sss-navbar-menu-item
          icon='cui-scania-fms-details'
          label={t('Anslagstavla')}
          class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
          <Link
            className='sss-navbar-menu-nav-link'
            to='/dashboard'
            onClick={() => tryAdobeLogPageView('dashboard', staffData)}>
            {t('Anslagstavla')}
          </Link>
          <span className='badge'></span>
        </sss-navbar-menu-item>
      }
      <sss-navbar-menu-item
        icon='cui-scania-fms-overview'
        label={t('Översikt')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/overview' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to={`/overview?periodStart=${overviewStartDate}&periodEnd=${overviewEndDate}`}
          onClick={() => tryAdobeLogPageView('overview', staffData)}>
          {t('Översikt')}
        </Link>
      </sss-navbar-menu-item>
      <sss-navbar-menu-item
        icon='cui-scania-fms-fuelreport'
        label={t('Bränslerapport')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/fuel-report' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to='/fuel-report'
          onClick={() => tryAdobeLogPageView('fuel-report', staffData)}>
          {t('Bränslerapport')}
        </Link>
      </sss-navbar-menu-item>
      <sss-navbar-menu-item
        icon='cui-scania-fms-settings'
        label={t('Inställningar')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/settings' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to='/settings'
          onClick={() => tryAdobeLogPageView('settings', staffData)}>
          {t('Inställningar')}
        </Link>
      </sss-navbar-menu-item>
    </sss-navbar-side-menu>
  );
};

export default SideMenu;

import { useEffect, useState } from 'react';
import { useShepherd } from 'react-shepherd';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useDebounce = (
  value: string,
  valueType: 'primitive' | 'reference' = 'primitive',
  delay: number = 500
) => {
  const isPrimitiveValue = valueType === 'primitive';
  const [debouncedValue, setDebouncedValue] = useState(
    isPrimitiveValue ? value : JSON.parse(value)
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(isPrimitiveValue ? value : JSON.parse(value));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, isPrimitiveValue]);

  return debouncedValue;
};

const useOverviewGuidedTour = () => {
  const Shepherd = useShepherd()
  const { showZendeskWidget } = useFlags()
  const {t} = useTranslation()
  const localStorageItemName = 'VP.ShowGuidedTour'

  const tourOptions = {
    defaultStepOptions: {
      classes: 'vp-shepherd-tour',
      cancelIcon: {
        enabled: true
      },
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 4,
      canClickTarget: false,
    },
    useModalOverlay: true
  }

  const beforeStepShowPromise = (selector: string) => {
    return new Promise<void>((resolve) => {
      const element = document.querySelector(selector);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY - 300;

        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        })
      }
      resolve()
    })
  }

  const generateProgressIndicator = () => {
    const currentStep = Shepherd.activeTour?.getCurrentStep()
    const currentStepElement = currentStep?.getElement()
    const footer = currentStepElement?.querySelector('.shepherd-footer')
    const progress = document.createElement('span')
    progress.className = 'tour-progress-indicator'
    if(currentStep && Shepherd.activeTour) {
      progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep)} / ${Shepherd.activeTour?.steps.length - 2}`
      footer?.insertAdjacentElement('afterbegin', progress)
    }
  }

  const tour = new Shepherd.Tour({
    ...tourOptions
  })

  // Step 1 comments to be removed if picture will be added in the future
  tour.addSteps([
    {
      id: 'step1',
      // text: "<h3>Introducing the new Vehicle Performance app!</h3><p>We're excited to unveil the new Vehicle Performance, now supporting BEV vehicles, enhanced performance, improved security, and much more.</p><p>While we aim to bring more features and enhancements from the previous version, your feedback will be very important in shaping the new Vehicle Performance.</p>",
      // classes: 'vp-tour-intro',
      title: t("VP_IntroducingNewVP"),
      text: `<p>${t("VP_GT_FordonsuppföljningGjortsOm")}</p> <p>${t('VP_GT_BättreStödElektriskaFordon')}</p> <p>${t('VP_GT_TaRundtur')}</p>`,
      classes: 'vp-intro',
      buttons: [
        {
          text: t("HoppaÖver"),
          classes: 'vp-shepherd_button-secondary',
          action() {
            tour.cancel()
          }
        },
        {
          text: t("Start"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ]
    },
    {
      id: 'step2',
      title: t("VP_GT_VäljGrupp"),
      text: t('VP_GT_SeData'),
      buttons: [
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#shepherd-groups-dropdown', on: 'bottom-start' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step3',
      title: t('VP_GT_HanteraVyer'),
      text: t("VP_GT_VäljStandardvyer"),
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#shepherd-views-container', on: 'bottom' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step4',
      title: t("VP_GT_SkapaVyer"),
      text: t("VP_GT_AnpassaRapporten"),
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#createViewButton', on: 'bottom' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step5',
      title: t("VP_GT_RedigeraVy"),
      text: `${t("VP_GT_RedigeraSjälv")} ${t("VP_GT_InteRedigeraStandardvyer")}`,
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#editViewButton', on: 'bottom' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step6',
      title: t("VP_GT_AnvändFilter"),
      text: t("VP_GT_HittaMedFilter"),
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#shepherd-filters-container', on: 'bottom' },
      beforeShowPromise: () => beforeStepShowPromise('#shepherd-filters-container'),
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step7',
      title: t("VP_GT_SeVemSomKört"),
      text: t("VP_GT_KlickaPåRad"),
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#shepherd-driver-details-button', on: 'right' },
      beforeShowPromise: () => beforeStepShowPromise('#shepherd-driver-details-button'),
      when: {
        show: generateProgressIndicator
      }
    }
  ])

  if(showZendeskWidget) {
    const shadowRoot = document.getElementById('zendesk-widget-container')?.shadowRoot
    const targetElement = shadowRoot?.querySelector(".help-menu-launcher") as HTMLElement
    
    if(targetElement) {
      tour.addStep({
        id: 'step8',
        title: t('VP_GT_SägVadDuTycker'),
        text: t('VP_GT_ViVillHöraDinaÅsikter'),
        buttons: [
          {
            text: t("Tillbaka"),
            classes: 'vp-shepherd_button-secondary',
            action: tour.back
          },
          {
            text: t("Nästa"),
            classes: 'vp-shepherd_button-primary',
            action: tour.next
          }
        ],
        attachTo: {
        element: targetElement, on: 'top'
      },
      when: {
        show: generateProgressIndicator
      }
    })
  }
  }

  tour.addStep({
    id: 'step9',
    title: t('VP_GT_DetVarAllt'),
    text: t('VP_GT_RedoAttAnvända'),
    buttons: [
      {
        text: t("Tillbaka"),
        classes: 'vp-shepherd_button-secondary',
        action: tour.back
      },
      {
        text: t("Stäng"),
        classes: 'vp-shepherd_button-primary',
        action: tour.complete
      }
    ],
  })

  useEffect(() => {
    ['complete', 'cancel'].forEach(event => {
      Shepherd.on(event, () => {
        localStorage.setItem(localStorageItemName, JSON.stringify(false))
    })
    })
  }, [Shepherd])
  
  return tour;
}

const useDashboardGuidedTour = () => {
  const Shepherd = useShepherd();
  const { t } = useTranslation();
  const localStorageItemName = 'VP.ShowDashboardGuidedTour';

  const tourOptions = {
    defaultStepOptions: {
      classes: 'vp-shepherd-tour',
      cancelIcon: {
        enabled: true
      },
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 4,
      canClickTarget: false,
    },
    useModalOverlay: true
  }

  const generateProgressIndicator = () => {
    const currentStep = Shepherd.activeTour?.getCurrentStep()
    const currentStepElement = currentStep?.getElement()
    const footer = currentStepElement?.querySelector('.shepherd-footer')
    const progress = document.createElement('span')
    progress.className = 'tour-progress-indicator'
    if(currentStep && Shepherd.activeTour) {
      progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep)} / ${Shepherd.activeTour?.steps.length - 1}`
      footer?.insertAdjacentElement('afterbegin', progress)
    }
  }

  const tour = new Shepherd.Tour({
    ...tourOptions
  })

  tour.addSteps([
    {
      id: 'step1',
      classes: 'vp-dashboard-tour-intro-initial-screen',
      text: `<h4>${t("VP_DashboardOnboardingTitle")}</h4/><p class='dashboard-guide-initial-screen-p'>${t("VP_DashboardOnboardingIntro")}</p> <p class='dashboard-guide-initial-screen-p'>${t('VP_DashboardOnboardingDesc')}</p> <div><ul class='guided-tour-list-item-title'><li><b>${t('VP_DashboardOnboardingCards')}</b></li></ul></div> <p class='guided-tour-list-item-description'>${t('VP_DashboardOnboardingCardsDesc')}</p> <div><ul class='guided-tour-list-item-title'><li><b>${t('VP_OnboardingCharts')}</b><span class='dashboard-guide-charts-beta-tag'>${t('KommerSnart_').replace('.', '')}</span></li></ul></div> <p class='guided-tour-list-item-description'>${t('VP_OnboardingChartsDesc')}</p>`,
      buttons: [
        {
          text: t("HoppaÖver"),
          classes: 'vp-shepherd_button-secondary',
          action() {
            tour.cancel()
          }
        },
        {
          text: t("LärDigMer"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ]
    },
    {
      id: 'step2',
      title: t("VP_DashboardsList"),
      text: t('VP_OnboardingChooseDashboard'),
      classes: 'vp-dashboard-tour-steps',
      buttons: [
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#shepherd-dashboards-dropdown', on: 'bottom-start' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step3',
      title: t('VP_CreateANewDashboard'),
      text: t("VP_OnboardingAddWidgets"),
      classes: 'vp-dashboard-tour-steps',
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Nästa"),
          classes: 'vp-shepherd_button-primary',
          action: tour.next
        }
      ],
      attachTo: { element: '#create-dashboard-button', on: 'bottom-end' },
      when: {
        show: generateProgressIndicator
      }
    },
    {
      id: 'step4',
      title: t("VP_ManageDashboard"),
      text: t("VP_ModifyCustomDashboards"),
      classes: 'vp-dashboard-tour-steps',
      buttons: [
        {
          text: t("Tillbaka"),
          classes: 'vp-shepherd_button-secondary',
          action: tour.back
        },
        {
          text: t("Stäng"),
          classes: 'vp-shepherd_button-primary',
          action: tour.complete
        }
      ],
      attachTo: { element: '#dashboard_actions_button', on: 'bottom-end' },
      when: {
        show: generateProgressIndicator
      }
    }
  ])

  useEffect(() => {
    ['complete', 'cancel'].forEach(event => {
      Shepherd.on(event, () => {
        localStorage.setItem(localStorageItemName, JSON.stringify(false))
    })
    })
  }, [Shepherd])
  
  return tour;
}

export { useDebounce, useOverviewGuidedTour, useDashboardGuidedTour };

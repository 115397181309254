import { TdsButton, TdsIcon } from '@scania/tegel-react';
import React from 'react';
import '../../styles/Filters.module.css';
import { t } from 'i18next';

interface FiltersButtonProps {
  isExpanded: boolean;
  onToggle: () => void;
}

const FiltersButton = ({ isExpanded, onToggle }: FiltersButtonProps) => {
  return (
    <TdsButton
      className="filters-button"
      modeVariant='secondary'
      variant='secondary'
      size='sm'
      text={t('VP_Filters')}
      animation='none'
      onClick={onToggle}
    >
      <TdsIcon slot='icon' size='16px' name='doner' />
    </TdsButton>
  );
};

export default FiltersButton;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';

import FuelReportTable from '../components/fuelReport/FuelReportTable';
import GroupsDropdown from '../components/dropdowns/GroupsDropdown';

import '../styles/GeneralStyles.css';
import styles from '../styles/FuelReport.module.css';

import { getMatchingPropForVehicleName } from '../utils/report/tableUtils';
import { TdsMessage, TdsSpinner } from '@scania/tegel-react';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const FuelReport = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);
  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [fuelReportStartDate, setFuelReportStartDate] = useState(defaultStartDate);
  const [fuelReportEndDate, setFuelReportEndDate] = useState(defaultEndDate);
  const [staffRetriesLimiter, setStaffRetriesLimiter] = useState<number>(1);

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
    refetch: refetchUserGroups,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);  

  useEffect(() => {
    if (isStaffLoadingError && staffRetriesLimiter <= 5) {
      setStaffRetriesLimiter(prevState => prevState + 1)
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData, staffRetriesLimiter]);
  
  useEffect(() => {
    if (isErrorLoadingGroups) {
      refetchUserGroups();
    }
  }, [isErrorLoadingGroups, refetchUserGroups]);

  const searchNewInterval = ({startDate, endDate}: {
    [key: string]: string;
  }) => {
    setFuelReportStartDate(dayjs(startDate).format(dateTimeFormat))
    setFuelReportEndDate(dayjs(endDate).format(dateTimeFormat))
  }

  return (
    <div className='mainContainer'>
      <div className='pageHeader'>
        <h2>{t('Bränslerapport')}</h2>
        { loggedIn && staffData && groupsLoadedSuccessfully && (
          <div className={styles.inlineFlexContainer}>
            <div className={styles.groupsDropdownWrapper}>
              <div className={styles.groupsDropdownContainer}>
                <GroupsDropdown
                  groups={customerGroups}
                  groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                  onSelection={setSelectedGroup}
                  isDisabled={false}
                  dropdownSize='sm'
                  label={t('Grupper')}
                  labelPosition='outside'
                />
              </div>
            </div>
            <div className={styles.overviewDatePickerContainer}>
              <DateTimeRangePicker
                periodStart={fuelReportStartDate}
                periodEnd={fuelReportEndDate}
                staffStartOfWeek={staffData.customer?.startOfWeek}
                staffLanguage={staffData.language}
                searchNewInterval={searchNewInterval}/>
            </div>
          </div>
        )}
      </div>
      { loggedIn && (
        <div className='pageContentContainer'>
          {(isStaffLoading || isCustomerGroupsFetching) && (
            <div className={styles.spinnerContainer}>
              <TdsSpinner size='md' />
            </div>
          )}
          { staffData && (
            <>
              { isErrorLoadingGroups && (
                <TdsMessage
                  variant='error'
                  header={t('EttFelHarUppstått_FörsökIgenSenare_')}
                />
              )}
              { groupsLoadedSuccessfully &&
                <FuelReportTable 
                  selectedGroup={selectedGroup}
                  vehicleIdentifier={getMatchingPropForVehicleName(staffData.vehicleIdentifier)}
                  propulsionConsumptionUnit={staffData.propulsionConsumptionUnit}
                  startDate={fuelReportStartDate}
                  endDate={fuelReportEndDate}
                  staffDetails={staffData}
                />
              }
            </>
          )}
        </div>
      )}
    </div>
  );
};


export default FuelReport;
import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';

import '../../styles/GeneralStyles.css';

type Props = {
  selectedOptions: string[];
  onSelection: Function;
  externalEquipmentReference: string | null;
  labelPosition?: 'inside' | 'outside';
  modeVariant?: 'primary' | 'secondary';
  minWidth?: string;
};


const VehicleTypeDropdown = ({ selectedOptions = [] , onSelection, externalEquipmentReference, labelPosition, modeVariant, minWidth }: Props) => {
  const { t } = useTranslation();
  const vehicleTypeDropdownRef = useRef<HTMLTdsDropdownElement>(null);

  const vehicleOptions = [
    { key: 'truck', value: 'truck', label: t('Lastbil') },
    { key: 'bus', value: 'bus', label: t('Buss') },
    { key: 'engine', value: 'engine', label: t('Motor') }
  ];

  useEffect(() => {
    if (selectedOptions.length === 0) {
      vehicleTypeDropdownRef.current?.reset();
    } else {
      vehicleTypeDropdownRef.current?.setValue(selectedOptions);
    }
  }, [selectedOptions]);

  const handleVehicleTypeSelection = useCallback(
    (e: any) => {
      let optionsCopy = [...selectedOptions];

      if (e.detail.selected && !optionsCopy.includes(e.detail.value)) {
        optionsCopy.push(e.detail.value);
      } else {
        optionsCopy = optionsCopy.filter((option) => option !== e.detail.value);
      }

      onSelection(optionsCopy);
    },
    [onSelection, selectedOptions]
  );

  useEffect(() => {
    const vehicleTypeDropdown = vehicleTypeDropdownRef.current;
    if (!vehicleTypeDropdown) return;

    vehicleTypeDropdown.addEventListener(
      'tdsSelect',
      handleVehicleTypeSelection
    );

    return () => {
      vehicleTypeDropdown.removeEventListener(
        'tdsSelect',
        handleVehicleTypeSelection
      );
    };
  }, [handleVehicleTypeSelection]);

  return (
    <div className='typeDropdownContainer' style={{minWidth: minWidth}}>
      <TdsDropdown
        ref={vehicleTypeDropdownRef}
        name='vehicleTypes'
        multiselect
        modeVariant={modeVariant || 'primary'}
        size='sm'
        placeholder={t('Välj')}
        label={t('TH_Fordonstyp')}
        label-position={labelPosition || 'outside'}
        disabled={externalEquipmentReference !== null}
        defaultValue={selectedOptions.toString()}>
        {vehicleOptions.map(option => (
          <TdsDropdownOption key={option.key} value={option.value}>
            {option.label}
          </TdsDropdownOption>
        ))}
      </TdsDropdown>
    </div>
  );
};

export default VehicleTypeDropdown;

import { useEffect, useState } from 'react';
import { getSpecificCookie } from '../../utils/general';
import { tryAdobeLogPageView } from '../../utils/adobeAnalytics';

const AdobeAnalyticsLoader = () => {
	const [cookieConsentObject, setCookieConsentObject] = useState(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'));

	useEffect(() => {
		window.addEventListener('cookiesConsentUpdated', () => {
			setCookieConsentObject(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'))
		});

		return () => {
			window.removeEventListener('cookiesConsentUpdated', () => {
				setCookieConsentObject(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'))
			});
		}
	}, []);

	useEffect(() => {
		if (cookieConsentObject && cookieConsentObject.performance) {
			// don't load the adobe analytics script on local environment
			if (window.location.hostname === 'localhost') {
				return;
			}

			const scriptEl = document.createElement('script');
			scriptEl.setAttribute('async', 'true');
			scriptEl.src = process.env.REACT_APP_ADOBE_SCRIPT_LINK || '';

			document.head.appendChild(scriptEl);
			scriptEl.addEventListener('load', () => {
				if (window) window.adobeDataLayer = window.adobeDataLayer || [];
				tryAdobeLogPageView(window.location.pathname.substring(1));
			});
		}
	}, [cookieConsentObject])

	return null;
}

export default AdobeAnalyticsLoader;
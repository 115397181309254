import { useCallback, useEffect, useState } from "react"
import { skipToken } from "@reduxjs/toolkit/query"

import { useGetStaffInfoQuery } from "../state/user/query"
import { useGetApmCredentialsQuery } from "../state/cloudwatch/query"
import AwsRumSingleton, { ApmCredentials } from '../utils/AwsRumSingleton'
import { deleteSpecificCookie, getSpecificCookie } from "../utils/general"

const AwsRumLoader = () => {
    const [cookieConsentObject, setCookieConsentObject] = useState(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'))
    const awsRumClient = AwsRumSingleton.getInstance()
    const { isSuccess: isStaffLoadingSuccess } = useGetStaffInfoQuery(null)
    const { 
        data: credentials, 
        isSuccess: isCredentialsLoadingSuccess, 
        isUninitialized: isCredentialsRequestNotInitialized, 
        refetch: refetchCredentials 
    } = useGetApmCredentialsQuery(isStaffLoadingSuccess && cookieConsentObject?.performance ? null : skipToken)

    const refetchCredentialsOnNewToken = useCallback(() => {
        if(!isCredentialsRequestNotInitialized && cookieConsentObject?.performance) {
            refetchCredentials()
        }
    }, [cookieConsentObject?.performance, isCredentialsRequestNotInitialized, refetchCredentials])

    useEffect(() => {
        window.addEventListener('newTokenStored', refetchCredentialsOnNewToken)

        return () => {
            window.removeEventListener('newTokenStored', refetchCredentialsOnNewToken)
        }
    }, [refetchCredentialsOnNewToken])

    useEffect(() => {
        if (awsRumClient && isCredentialsLoadingSuccess && cookieConsentObject?.performance) {
            const { accessKeyId, secretAccessKey, sessionToken } = credentials as ApmCredentials

            if (accessKeyId && secretAccessKey && sessionToken) {
                awsRumClient.setAwsCredentials({
                    accessKeyId,
                    secretAccessKey,
                    sessionToken
                })
            } else {
                console.error('Invalid Cloudwatch RUM credentials')
            }
        }
    }, [awsRumClient, cookieConsentObject?.performance, credentials, isCredentialsLoadingSuccess])

    useEffect(() => {
        window.addEventListener('cookiesConsentUpdated', () => {
            setCookieConsentObject(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'))
        })

        return () => {
            window.removeEventListener('cookiesConsentUpdated', () => {
                setCookieConsentObject(JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}'))
            })
        }
    }, [])

    useEffect(() => {
        if (awsRumClient) {
            if (cookieConsentObject?.performance) {
                awsRumClient?.enable()
                awsRumClient?.allowCookies(true)
            } else {
                awsRumClient?.disable()
                awsRumClient?.allowCookies(false)
                deleteSpecificCookie('cwr_s')
                deleteSpecificCookie('cwr_u')
            }
        }
    }, [awsRumClient, cookieConsentObject?.performance])

    return null;
}
export default AwsRumLoader
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export type ApmCredentials = {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
}

export default class AwsRumSingleton {
  private static instance: AwsRum | null = null;

  private constructor() {}

  public static initialize(): void {
    if (!AwsRumSingleton.instance) {
      const APPLICATION_ID = process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_ID || '';
      const APPLICATION_VERSION = process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_VERSION || '1.0.0';
      const APPLICATION_REGION = process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_REGION || 'eu-west-1';
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        sessionEventLimit: 500,
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: [
          "performance",
          "errors",
          ['http', {
            addXRayTraceIdHeader: [
              /^https:\/\/vehicleperformance-bff\.testapivp\.cs\.scania\.com(\/.*)?$/gm,
              /^https:\/\/vehicleperformance-bff\.apivp\.cs\.scania\.com(\/.*)?$/gm
            ],
            urlsToExclude: [
              /^https:\/\/api\.myscania\.cw\.csawsdev\.net\/apps\/profiles\/self\/picture$/gm,
              /^https:\/\/api\.myscania\.test\.cs\.scania\.com\/apps\/profiles\/self\/picture$/gm,
              /^https:\/\/api\.myscania\.cs\.scania\.com\/apps\/profiles\/self\/picture$/gm,
              /^https:\/\/customersupport\.scania\.com$/gm,
              /^https:\/\/fmpmessages\.cw\.csawsdev\.net\/v2\/disturbance-messages\/private.*/gm,
              /^https:\/\/fmpmessages\.apicw\.cs\.scania\.com\/v2\/disturbance-messages\/private.*/gm
            ]
          }],
          ['interaction', {
            enableMutationObserver: true,
            events: []
          }]
        ],
        enableXRay: true
      };

      AwsRumSingleton.instance = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    }
  }

  public static getInstance() {
    this.checkInstanceInitialized()
    
    return AwsRumSingleton;
  }

  public static setAwsCredentials(credentials: ApmCredentials) {
    this.checkInstanceInitialized()

    AwsRumSingleton.instance?.setAwsCredentials(credentials)
  }

  public static enable() {
    this.checkInstanceInitialized()

    AwsRumSingleton.instance?.enable()
  }

  public static disable() {
    this.checkInstanceInitialized()

    AwsRumSingleton.instance?.disable()
  }

  public static allowCookies(shouldAllowCookies: boolean) {
    this.checkInstanceInitialized()

    AwsRumSingleton.instance?.allowCookies(shouldAllowCookies)
  }

  public static sendEvent(eventType: string, payload: Record<any, any> = {}) {
    this.checkInstanceInitialized()

    AwsRumSingleton.instance?.recordEvent(eventType, payload)
  }

  public static sendError(error: any) {
    this.checkInstanceInitialized()
    
    AwsRumSingleton.instance?.recordError(error)
  }

  private static checkInstanceInitialized() {
    if (!AwsRumSingleton.instance) {
      throw new Error('AwsRumSingleton not initialized');
    }
  }
}
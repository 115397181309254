import { useRouteError } from 'react-router-dom';
import { useGetStaffInfoQuery } from '../../state/user/query';
import ThemeLoader from './ThemeLoader';
import Header from './Header';
import SideMenu from './SideMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tryAdobeLogError } from '../../utils/adobeAnalytics';

const ErrorPage = () => {
  const { t } = useTranslation();
  const [staffRetriesLimiter, setStaffRetriesLimiter] = useState<number>(1);
  const [adobePageLoadLogginglimiter, setAdobepageLoadLogginglimiter] = useState<number>(0);

  const {
    data: staffData,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  useEffect(() => {
    if (isStaffLoadingError && staffRetriesLimiter <= 5) {
      setStaffRetriesLimiter(prevState => prevState + 1)
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData, staffRetriesLimiter]);

  const staffLanguage = isStaffLoadingSuccess ? staffData.language : 'en-GB';

  const error = useRouteError();
  console.error('ERROR:    ', error);
  
  useEffect(() => {
    if (staffData && adobePageLoadLogginglimiter < 1) {
      tryAdobeLogError(error);
      setAdobepageLoadLogginglimiter(prevState => prevState + 1);
    }
  }, [staffData, adobePageLoadLogginglimiter, error])
  
  return <>
    <ThemeLoader />
    <Header language={staffLanguage} />
    <SideMenu staffData={staffData} />
    
    <div className='mainContainer'>
      <div className='pageHeader'>
        <h2>{t('EttFelHarUppstått')}</h2>
      </div>
    </div>
  </>
}

export default ErrorPage;
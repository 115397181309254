import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import { TdsButton, TdsDivider, TdsIcon, TdsLink, TdsMessage, TdsSpinner, } from '@scania/tegel-react';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';
import { useDeleteDashboardMutation,
  useGetDashboardSpecificationQuery,
  useGetUserViewsQuery,
  useUpdateDashboardMutation
} from '../state/views/query';
import { useGetStaffSettingsQuery } from '../state/settingsApi/query';

import '../styles/GeneralStyles.css';
import '../styles/ShepherdGuidedTour.css';
import styles from '../styles/Dashboard.module.css';

import ViewsDropdown from '../components/dropdowns/ViewsDropdown';
import GroupsDropdown from '../components/dropdowns/GroupsDropdown';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';
import DashboardWidgets from '../components/dashboard/DashboardWidgets';
import FiltersButton from '../components/filters/FiltersButton';
import FiltersContent from '../components/filters/FiltersContent';
import ToastContainer from '../components/ToastContainer';
import DashboardModal from '../components/dashboard/DashboardModal';
import TableWidgetModal from '../components/dashboard/TableWidgetModal';
import DashboardActions from '../components/dashboard/DashboardActions';
import ConfirmationModal from '../components/common/ConfirmationModal';

import { capitalizeFirstLetter, transformStringsToLowerCase } from '../utils/general';
import { uuidv4 } from '../utils/api';
import { useDashboardGuidedTour } from '../utils/hooks';
import AwsRumSingleton from '../utils/AwsRumSingleton';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const Dashboard = () => {
  const awsRumClient = AwsRumSingleton.getInstance();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);

  const { showDashboardPage } = useFlags();

  const [updateDashboard] = useUpdateDashboardMutation();

  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedDashboardRef, setSelectedDashboardRef] = useState('');
  const [widgetReferenceToEdit, setWidgetReferenceToEdit] = useState('');
  const [widgetReferenceToDelete, setWidgetReferenceToDelete] = useState('');
  const [dashboardStartDate, setDashboardStartDate] = useState(defaultStartDate);
  const [dashboardEndDate, setDashboardEndDate] = useState(defaultEndDate);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [staffRetriesLimiter, setStaffRetriesLimiter] = useState<number>(1);
  const [toastsToDisplay, setToastsToDisplay] = useState<Array<Toast>>([]);
  const [dashboardModalSelector, setDashboardModalSelector] = useState('');
  const [widgetModalSelector, setWidgetModalSelector] = useState<'createWidgetButton' | 'editTableWidgetButton' | ''>('');
  const [isSelectedDashboardNotEditable, setIsSelectedDashboardNotEditable] = useState(false);
  const [updatedDashboardFilters, setUpdatedDashboardFilters] = useState<boolean>(false);
  const [confirmationSelector, setConfirmationSelector] = useState<string>('');
  const [deleteWidgetConfirmationSelector, setDeleteWidgetConfirmationSelector] = useState<'deleteTableWidgetSelector' | 'deleteKPIWidgetSelector' | ''>('');
  const [deleteDashboard] = useDeleteDashboardMutation();

  const guidedTour = useDashboardGuidedTour();
  const [shouldShowGuidedTour, setShouldShowGuidedTour] = useState<boolean>(false);
  const [isTourStarted, setIsTourStarted] = useState<boolean>(false);

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const { data: staffSettings } = useGetStaffSettingsQuery(isStaffLoadingSuccess ? null : skipToken)

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);

  const {
    data: fetchedDashboards,
    isSuccess: isDashboardsLoadedSuccessfully,
    isError: isErrorLoadingDashboards,
    isFetching: isDashboardsFetching,
  } = useGetUserViewsQuery(isStaffLoadingSuccess ? null : skipToken);

  const {
    data: selectedDashboardSpecs,
    isSuccess: dashboardSpecsLoadedSuccessfully,
    isError: isErrorLoadingDashboardSpecs,
    isFetching: isDashboardSpecsFetching,
    isLoading: isDashboardSpecsLoading,
  } = useGetDashboardSpecificationQuery(
    selectedDashboardRef && selectedDashboardRef.length > 0 ? selectedDashboardRef : skipToken
  );

  useEffect(() => {
    if (isStaffLoadingError && staffRetriesLimiter <= 5) {
      setStaffRetriesLimiter(prevState => prevState + 1)
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData, staffRetriesLimiter]);

  useEffect(() => {
    if (isDashboardsLoadedSuccessfully && !isDashboardsFetching && fetchedDashboards?.length) {
      const selectedDashboard = fetchedDashboards.find((dashboard: Dashboard) => dashboard.isDefault === true);

      if (selectedDashboard && selectedDashboard.dashboardReference) {
        setSelectedDashboardRef(selectedDashboard.dashboardReference);
        setIsSelectedDashboardNotEditable(selectedDashboard?.title && (fetchedDashboards.find((dashboard: Dashboard) => dashboard.dashboardReference === selectedDashboard.dashboardReference)?.permission?.scope === 'global' || selectedDashboard.dashboardReference === '03b89b46-6b28-4828-935b-cc12ec97e4bf'));
      }
    }
  }, [fetchedDashboards, isDashboardsFetching, isDashboardsLoadedSuccessfully]);

  useEffect(() => {
    if (selectedDashboardSpecs && !isDashboardSpecsFetching) {
      const preselectedFuelTypeFilters = selectedDashboardSpecs.properties?.filters?.find((filter: { fuelTypes: string | any[]; }) => filter.fuelTypes?.length > 0) || [];
      if (preselectedFuelTypeFilters) {
        setSelectedFuelTypes(transformStringsToLowerCase(preselectedFuelTypeFilters.fuelTypes));
      }

      const preselectedVehicleTypeFilters = selectedDashboardSpecs.properties?.filters?.find((filter: { equipmentTypes: string | any[]; }) => filter.equipmentTypes?.length > 0) || [];
      if (preselectedVehicleTypeFilters) {
        setSelectedVehicleTypes(transformStringsToLowerCase(preselectedVehicleTypeFilters.equipmentTypes));
      }

      setUpdatedDashboardFilters(true);
    }
  }, [isDashboardSpecsFetching, selectedDashboardSpecs]);

  useEffect(() => {
    const userHasCompletedMyScaniaTour = staffSettings?.scpNavbarTour?.header && staffSettings?.scpNavbarTour?.sidenav;
    const showGuidedTour = localStorage.getItem('VP.ShowDashboardGuidedTour') !== 'false' && userHasCompletedMyScaniaTour;

    setShouldShowGuidedTour(showGuidedTour);
  }, [staffSettings, setShouldShowGuidedTour]);

  useEffect(() => {
    const isInMobileView = window.matchMedia('(max-width: 768px)').matches;

    const enableDashboardTour = (e: any) => {
      if(isInMobileView && e.type === 'navbarHeaderTourCompleted') {
        const backdrop = document.querySelector('.sss-navbar__overlay--expanded') as HTMLElement;
        backdrop?.click()
      }
      setShouldShowGuidedTour(true);
    };

    const mobileEvents = ['navbarHeaderTourCanceled', 'navbarHeaderTourCancelIcon', 'navbarHeaderTourCompleted'];
    const desktopEvents = ['navbarSidenavTourCanceled', 'navbarSidenavTourCancelIcon', 'navbarSidenavTourCompleted'];

    const addEventListeners = (events: typeof mobileEvents | typeof desktopEvents) => {
      events.forEach(event => document.addEventListener(event, enableDashboardTour));
    };

    const removeEventListeners = (events: typeof mobileEvents | typeof desktopEvents) => {
      events.forEach(event => document.removeEventListener(event, enableDashboardTour));
    };

    if (isInMobileView) {
      addEventListeners(mobileEvents);
      return () => removeEventListeners(mobileEvents);
    } else {
      addEventListeners(desktopEvents);
      return () => removeEventListeners(desktopEvents);
    }
  }, []);

  useEffect(() => {
    if (staffData &&
        localStorage.getItem('VP.ShowDashboardGuidedTour') !== 'false' &&
        shouldShowGuidedTour &&
        !isTourStarted &&
        dashboardSpecsLoadedSuccessfully) {
      guidedTour.start();
      setIsTourStarted(true);
    }
  }, [staffData, guidedTour, isTourStarted, shouldShowGuidedTour, dashboardSpecsLoadedSuccessfully])

  const searchNewInterval = ({
    startDate,
    endDate,
  }: {
    [key: string]: string;
  }) => {
    setDashboardStartDate(dayjs(startDate).format(dateTimeFormat));
    setDashboardEndDate(dayjs(endDate).format(dateTimeFormat));
  };

  const pushNotificationToToast = (newNotification: Toast) => {
    setToastsToDisplay((toasts) => [...toasts, newNotification]);

    setTimeout(() => {
      removeToast(newNotification.id);
    }, 5000);
  };

  const removeToast = (toastId: number | string) => {
    setToastsToDisplay((toasts) =>
      toasts.filter((toast) => toast.id !== toastId)
    );
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const clearAndSaveFilters = async () => {
    if (isSelectedDashboardNotEditable) {
      // we don't save the filters on global dashboards but apply them and update widgets data
      setSelectedFuelTypes([]);
      setSelectedVehicleTypes([]);
      return;
    }

    const tempPayload: DashboardDetails = {
      dashboardReference: selectedDashboardRef && selectedDashboardRef?.length > 0  ? selectedDashboardRef : undefined,
      properties: {
        filters: []
      },
      title: selectedDashboardSpecs.title,
      widgets: selectedDashboardSpecs.widgets,
    };    
      
    try {
      await updateDashboard(tempPayload).unwrap();
      // not showing a toast message on success as it might confuse the user with all the toats and 
      // in the future we'll move to a signle save request for everything per page after user clicks a button
    } catch (err) {
      pushNotificationToToast({
        id: uuidv4(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  const updateDashboardFuelTypeFilters = async (newFuelTypeFilters: string[]) => {
    if (isSelectedDashboardNotEditable) {
      // we don't save the filters on global dashboards but apply them and update widgets data
      setSelectedFuelTypes(newFuelTypeFilters);
      return;
    }

    const fuelTypeFiltersIndex = selectedDashboardSpecs?.properties?.filters?.findIndex((filter: { fuelTypes: string | any[]; }) => filter?.fuelTypes?.length >= 0);
    const currentFilters = selectedDashboardSpecs?.properties?.filters || [];
    const transformedFilterValues = newFuelTypeFilters.map(fuelFilter => capitalizeFirstLetter(fuelFilter));
  
    setSelectedFuelTypes(newFuelTypeFilters);
    const updatedFilters = [...currentFilters];
    updatedFilters[fuelTypeFiltersIndex > -1 ? fuelTypeFiltersIndex : currentFilters.length] = { fuelTypes: transformedFilterValues };

    if (selectedDashboardRef && selectedDashboardSpecs) {
      // updating the new selected filters for the selected dashboard all the way to the database
      const tempPayload: DashboardDetails = {
        dashboardReference: selectedDashboardRef && selectedDashboardRef?.length > 0  ? selectedDashboardRef : undefined,
        properties: {
          filters: updatedFilters
        },
        title: selectedDashboardSpecs.title,
        widgets: selectedDashboardSpecs.widgets,
      };
      
      try {
        await updateDashboard(tempPayload).unwrap();
        // not showing a toast message on success as it might confuse the user with all the toats and 
        // in the future we'll move to a signle save request for everything per page after user clicks a button
      } catch (err) {
        pushNotificationToToast({
          id: uuidv4(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  }

  const updateDashboardVehicleTypeFilters = async (newEquipmentTypeFilters: string[]) => {
    if (isSelectedDashboardNotEditable) {
      // we don't save the filters on global dashboards but apply them and update widgets data
      setSelectedVehicleTypes(newEquipmentTypeFilters);
      return;
    }

    const equipmentTypeFiltersIndex = selectedDashboardSpecs?.properties?.filters?.findIndex((filter: { equipmentTypes: string | any[]; }) => filter?.equipmentTypes?.length >= 0);
    const currentFilters = selectedDashboardSpecs?.properties?.filters || [];
    const transformedFilterValues = newEquipmentTypeFilters.map(equipmentTypeFilter => capitalizeFirstLetter(equipmentTypeFilter));
  
    setSelectedVehicleTypes(newEquipmentTypeFilters);
    const updatedFilters = [...currentFilters];
    updatedFilters[equipmentTypeFiltersIndex > -1 ? equipmentTypeFiltersIndex : currentFilters.length] = { equipmentTypes: transformedFilterValues };

    if (selectedDashboardRef && selectedDashboardSpecs) {
      // updating the new selected filters for the selected dashboard all the way to the database
      const tempPayload: DashboardDetails = {
        dashboardReference: selectedDashboardRef && selectedDashboardRef?.length > 0  ? selectedDashboardRef : undefined,
        properties: {
          filters: updatedFilters
        },
        title: selectedDashboardSpecs.title,
        widgets: selectedDashboardSpecs.widgets,
      };
      
      try {
        await updateDashboard(tempPayload).unwrap();
        // not showing a toast message on success as it might confuse the user with all the toats and 
        // in the future we'll move to a signle save request for everything per page after user clicks a button
      } catch (err) {
        pushNotificationToToast({
          id: uuidv4(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  }

  const selectedCount = (selectedFuelTypes.length > 0 ? 1 : 0) + (selectedVehicleTypes.length > 0 ? 1 : 0);

  const deleteCurrentDashboard = async () => {
    try {
      if (selectedDashboardRef && selectedDashboardRef.length > 0) {
        setConfirmationSelector('');

        await deleteDashboard(selectedDashboardRef).unwrap()

        pushNotificationToToast({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: t('VP_DashboardDeleted'),
          variant: 'success',
        });
      }

    } catch (err) {
      pushNotificationToToast({
        id: uuidv4(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  const deleteTableWidgetByReference = async () => {
    if (widgetReferenceToDelete && widgetReferenceToDelete.length > 0) {
      let tempPayload = structuredClone(selectedDashboardSpecs)

      const deletedWidgetIndex = tempPayload.widgets.findIndex((widget: WidgetSpecification) => widget.widgetReference === widgetReferenceToDelete)

      if (deletedWidgetIndex !== -1) {
        tempPayload.widgets = [...tempPayload.widgets.slice(0, deletedWidgetIndex), ...tempPayload.widgets.slice(deletedWidgetIndex + 1)]
      }

      try {
        await updateDashboard(tempPayload)
        pushNotificationToToast({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: t('ÄndringarnaHarSparats'),
          variant: 'success',
        });
        setDeleteWidgetConfirmationSelector('')
        setWidgetReferenceToDelete('')
      } catch (err) {
        pushNotificationToToast({
          id: uuidv4(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  }

  return (
    <div className='mainContainer isolatedContainer'>
      <div className='stickyHeader'>
        <div className='pageHeader'>
          <div className='header2'>
            <h2 className={styles.dashboardHeader}>{t('Anslagstavla')}</h2>
            <div className='betaTag'><sss-navbar-beta></sss-navbar-beta></div>
          </div>
          {loggedIn && staffData && showDashboardPage && (
            <div className={styles.inlineFlexContainer}>
              {fetchedDashboards && fetchedDashboards.length > 0 && !isDashboardsFetching && (
                <>
                  <ViewsDropdown
                    id='shepherd-dashboards-dropdown'
                    views={fetchedDashboards}
                    triggerUpdateOfDashboardFilters={() => setUpdatedDashboardFilters(false)}
                    labelPosition='outside'
                    label={t('Anslagstavla')}
                    size='sm'
                    modeVariant='secondary'
                  />
                  <div className={styles.createDashboardButton}>
                  <TdsButton
                    id='create-dashboard-button'
                    data-rum-id='createDashboardButton'
                    type='button'
                    variant='primary'
                    size='sm'
                    text={t('VP_CreateDashboard')}
                    onClick={() => {
                      setDashboardModalSelector('createDashboard')
                      awsRumClient.sendEvent('start-create-dashboard')
                    }
                  }
                  />
                  </div>
                  <DashboardActions
                    isSelectedDashboardNotEditable={isSelectedDashboardNotEditable}
                    isDisabled={!dashboardSpecsLoadedSuccessfully || isDashboardSpecsFetching || isDashboardSpecsLoading}
                    setDashboardModalSelector={setDashboardModalSelector}
                    setConfirmationSelector={setConfirmationSelector}
                  />

                  <DashboardModal
                    header={dashboardModalSelector === 'editDashboard' ? t('VP_EditDashboard') : dashboardModalSelector === 'copyDashboard' ? t('VP_CloneDashboard') : t('VP_CreateADashboard')}
                    selector={dashboardModalSelector}
                    dashboardDetails={selectedDashboardSpecs || null}
                    selectedDashboardRef={selectedDashboardRef}
                    pushNotification={pushNotificationToToast}
                    updateSelectedDashboard={setSelectedDashboardRef}
                    setSelector={setDashboardModalSelector}
                  />
                  <ConfirmationModal
                    size='xs'
                    selector={confirmationSelector}
                    header={t('VP_DeleteDashboard')}
                    message={t('VP_DeleteDashboardQuestion')}
                    onCancel={() => setConfirmationSelector('')}
                    onConfirm={deleteCurrentDashboard}
                    confirmButtonText={t('TaBort')}
                    danger
                  />
                  <ConfirmationModal
                    size='xs'
                    selector={deleteWidgetConfirmationSelector}
                    header={t('VP_DeleteWidget')}
                    message={`${t('VP_DeleteWidgetQuestion')} ${t('ÅtgärdenKanInteÅngras')}.`}
                    onCancel={() => setDeleteWidgetConfirmationSelector('')}
                    onConfirm={deleteTableWidgetByReference}
                    confirmButtonText={t('TaBort')}
                    danger
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className='dividerWrapper'>
         <TdsDivider />
        </div>
       
        {loggedIn && staffData && !isStaffLoading && !showDashboardPage && (
          <div className={styles.dashboardPageContainer}>{t('DuHarInteBehörighet_')}</div>
        )}
        
        {loggedIn && showDashboardPage && (
          <div className={styles.dashboardPageContainer}>
            {(isErrorLoadingGroups || isErrorLoadingDashboards) && (
              <TdsMessage
                variant='error'
                header={t('EttFelHarUppstått_FörsökIgenSenare_')}
              />
            )}

            {(isStaffLoading || isCustomerGroupsFetching) && (
              <div className='spinnerContainer'>
                <TdsSpinner size='md' />
              </div>
            )}

            {staffData && groupsLoadedSuccessfully && (
              <div className={styles.flexContainer}>
                <div className={styles.groupsDropdownWrapper}>
                <div className={styles.groupsDropdownContainer}>
                  <GroupsDropdown
                    groups={customerGroups}
                    groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                    onSelection={setSelectedGroup}
                    isDisabled={false}
                    variantMode='secondary'
                    dropdownSize='sm'
                    label={t('Grupper')}
                    labelPosition='outside'
                  />
                </div>
                </div>
                <div className={styles.overviewDatePickerContainer}>
                  <DateTimeRangePicker
                    periodStart={dashboardStartDate}
                    periodEnd={dashboardEndDate}
                    staffStartOfWeek={staffData.customer?.startOfWeek}
                    staffLanguage={staffData.language}
                    searchNewInterval={searchNewInterval}
                    modeVariant='primary'
                  />
                </div>
                { dashboardSpecsLoadedSuccessfully && updatedDashboardFilters && <>
                  <div className={styles.filtersButtonContainer}>
                    <FiltersButton
                      isExpanded={isExpanded}
                      onToggle={handleToggle}
                    />
                    {selectedCount > 0 && (
                      <>
                        <span className={styles.filterBadge}>
                          <span>{selectedCount}</span>
                        </span>
                      </>
                    )}
                  </div>
                  {selectedCount > 0 && (
                    <TdsLink className={styles.clearfiltersLink}>
                      <span onClick={clearAndSaveFilters} className={styles.clearFiltersButton}>
                        {t('VP_ClearFilters')}
                      </span>
                    </TdsLink>
                  )}
                </> }

                {isDashboardsLoadedSuccessfully &&
                  dashboardSpecsLoadedSuccessfully &&
                  selectedDashboardRef && fetchedDashboards.find((dashboard: Dashboard) => dashboard.dashboardReference === selectedDashboardRef)?.permission?.scope !== 'global' &&
                  selectedDashboardSpecs.widgets.length > 0 && (
                    <div className='addWidgetButton' style={{ marginLeft: 'auto' }}>
                      <TdsButton type='button' variant='secondary' size='sm' text={t('VP_AddWidget')} mode-variant='secondary' onClick={() => setWidgetModalSelector('createWidgetButton')}>
                        <TdsIcon slot='icon' size='16px' name='plus' />
                      </TdsButton>
                    </div>
                  )
                }

                {isDashboardsLoadedSuccessfully &&
                  dashboardSpecsLoadedSuccessfully &&
                  widgetModalSelector.length > 0 && (
                    <TableWidgetModal
                      header={widgetModalSelector === 'editTableWidgetButton' ? t('VP_EditWidget') : t('VP_CreateTableWidget')}
                      selector={widgetModalSelector}
                      size='lg'
                      propulsionConsumptionUnit={staffData.propulsionConsumptionUnit}
                      gasConsumptionUnit={staffData.gasConsumptionUnit}
                      pushNotification={pushNotificationToToast}
                      updateSelectedDashboard={setSelectedDashboardRef}
                      setWidgetModalSelector={setWidgetModalSelector}
                      currentDashboard={selectedDashboardSpecs}
                      widgetRefToEdit={widgetReferenceToEdit}
                    />
                  )}
                  { dashboardSpecsLoadedSuccessfully && updatedDashboardFilters &&
                    <div className='fullWidth'>
                      <FiltersContent
                        isExpanded={isExpanded}
                        onClose={handleClose}
                        selectedFuelTypes={selectedFuelTypes} 
                        setSelectedFuelTypes={updateDashboardFuelTypeFilters} 
                        selectedVehicleTypes={selectedVehicleTypes} 
                        setSelectedVehicleTypes={updateDashboardVehicleTypeFilters} 
                        externalEquipmentReference={null}
                      />
                    </div>
                  }
              </div>
            )}
          </div>
        )}
      </div>
      {showDashboardPage && isErrorLoadingDashboardSpecs &&
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      }
      {showDashboardPage && (isDashboardSpecsFetching || isDashboardSpecsLoading) && (
        <div className='spinnerContainer'>
          <TdsSpinner size='md' />
        </div>
      )}
      {loggedIn &&
        showDashboardPage &&
        staffData &&
        groupsLoadedSuccessfully &&
        dashboardSpecsLoadedSuccessfully &&
        !isDashboardSpecsFetching &&
        !isDashboardSpecsLoading &&
        updatedDashboardFilters && (
          <DashboardWidgets
            staffDetails={staffData}
            periodStart={dashboardStartDate}
            periodEnd={dashboardEndDate}
            selectedGroup={selectedGroup}
            selectedDashboardSpecs={selectedDashboardSpecs}
            fuelTypes={selectedFuelTypes}
            vehicleTypes={selectedVehicleTypes}
            updatedDashboardFilters={updatedDashboardFilters}
            setWidgetModalSelector={setWidgetModalSelector}
            onDeleteWidget={(widgetRef: string) => {setDeleteWidgetConfirmationSelector('deleteTableWidgetSelector'); setWidgetReferenceToDelete(widgetRef)}}
            setWidgetReferenceToEdit={setWidgetReferenceToEdit}
            isSelectedDashboardNotEditable={isSelectedDashboardNotEditable}
          />
        )}
      {toastsToDisplay.length > 0 && (
        <ToastContainer toasts={toastsToDisplay} removeToast={removeToast} />
      )}
    </div>
  );
};

export default Dashboard;

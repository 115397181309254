import { useRef } from "react";
import { TdsButton, TdsDivider, TdsIcon, TdsPopoverMenu, TdsPopoverMenuItem } from "@scania/tegel-react";
import { useTranslation } from "react-i18next";

import styles from '../../styles/DashboardActions.module.css'

type Props = {
    isSelectedDashboardNotEditable: boolean;
    isDisabled: boolean;
    setDashboardModalSelector: Function;
    setConfirmationSelector: Function;
}

const DashboardActions = ({ isSelectedDashboardNotEditable, isDisabled, setDashboardModalSelector, setConfirmationSelector }: Props) => {
    const { t } = useTranslation();
    const popoverRef = useRef<HTMLTdsPopoverMenuElement | null>(null);

    return (
        <>
            <TdsButton
                type='button'
                id='dashboard_actions_button'
                variant='secondary'
                size='sm'
                mode-variant='secondary'
                className={styles.dashboardKebabButton}
                disabled={isDisabled || undefined}
            >
                <TdsIcon slot='icon' size='16px' name='kebab'></TdsIcon>
            </TdsButton>
            <TdsPopoverMenu id='dashboard-actions-menu' placement='bottom-end' selector='#dashboard_actions_button' ref={popoverRef}>
                <div>
                    {!isSelectedDashboardNotEditable && (
                        <>
                            <TdsPopoverMenuItem>
                                <button onClick={() => { popoverRef.current?.close(); setDashboardModalSelector('editDashboard') }}>
                                    <TdsIcon name='edit' />
                                    <span>
                                        {t('Redigera')}
                                    </span>
                                </button>
                            </TdsPopoverMenuItem>
                            <TdsDivider />
                        </>
                    )}
                </div>
                <TdsPopoverMenuItem>
                    <button onClick={() => { popoverRef.current?.close(); setDashboardModalSelector('copyDashboard') }}>
                        <TdsIcon name='copy' />
                        {t('VP_Clone')}
                    </button>
                </TdsPopoverMenuItem>
                <div>
                    {!isSelectedDashboardNotEditable && (
                        <>
                            <TdsDivider />
                            <TdsPopoverMenuItem>
                                <button onClick={() => { popoverRef.current?.close(); setConfirmationSelector('deleteCurrentDashboard') }}>
                                    <TdsIcon name='trash' />
                                    <span>
                                        {t('TaBort')}
                                    </span>
                                </button>
                            </TdsPopoverMenuItem>
                        </>
                    )}
                </div>
            </TdsPopoverMenu>
        </>
    )
}
export default DashboardActions
import { useRef, useState, useCallback, useEffect, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsFolderTab,
  TdsFolderTabs,
  TdsIcon,
  TdsLink,
  TdsModal,
  TdsSpinner,
  TdsTextField,
  TdsTooltip,
} from '@scania/tegel-react';

import { useUpdateDashboardMutation } from '../../state/views/query';
import { groupParams } from '../../utils/report/paramsGroups';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';
import { allGroupsFullySelected, getGroupsCountPerCategory, isAccordionItemExpanded, updateParamsList } from '../../utils/views';
import ParameterGroupsContainer from '../containers/ParameterGroupsContainer';
import styles from '../../styles/ViewModal.module.css';
import { uuidv4 } from '../../utils/api';
import TableHeaderReorderContainer from '../containers/TableHeaderReorderContainer';
import { getTableParamsExpanded } from '../../utils/general';

type TableWidgetModalProps = {
  header: string;
  selector: 'createWidgetButton' | 'editTableWidgetButton' | '';
  size: ModalSize;
  propulsionConsumptionUnit: string;
  gasConsumptionUnit: string;
  pushNotification: Function;
  updateSelectedDashboard: Function;
  setWidgetModalSelector: Function;
  currentDashboard: DashboardDetails;
  widgetRefToEdit: string;
};

const TableWidgetModal = ({
  header,
  selector,
  size,
  propulsionConsumptionUnit,
  gasConsumptionUnit,
  pushNotification,
  updateSelectedDashboard,
  setWidgetModalSelector,
  currentDashboard,
  widgetRefToEdit
}: TableWidgetModalProps) => {
  const { t } = useTranslation();
  const [updateDashboard, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();
  const widgetSpec = currentDashboard.widgets.find((widget: WidgetSpecification) => widget.widgetReference === widgetRefToEdit)
  const [tableNameValue, setTableNameValue] = useState<string>('');
  const [searchedText, setSearchedText] = useState<string>('');
  const [tableNameError, setTableNameError] = useState(false);
  const [tableMetrics, setTableMetrics] = useState<Array<{
    columnName: string;
    subColumns: Array<string>
  }>>([]);
  const [visibleCategories, setVisibleCategories] = useState(Array(groupParams.length).fill(0, 0));
  const [paramsSelected, setParamsSelected] = useState(false);
  const [expandedParameterGroups, setExpandedParameterGroups] = useState<Array<string>>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [metricsGroupsOrder, setMetricsGroupsOrder] = useState<Array<string>>([]);
  const [unitsSubcolumnsOrder, setUnitsSubcolumnsOrder] = useState<Array<{ columnName: string; unitsOrder: Array<string>; }>>([]);
  const [expandedBeforeReorder, setExpandedBeforeReorder] = useState<Array<number>>([]);
  const [hasModalLoaded, setHasModalLoaded] = useState(false);

  const tableNameElement = useRef<HTMLTdsTextFieldElement>(null);
  const tableModalElement = useRef<HTMLTdsModalElement>(null);
  const searchInputRef = useRef<HTMLTdsTextFieldElement>(null);
  const tabsRef = useRef<HTMLTdsFolderTabsElement>(null);

  const handleNameChange = useCallback(
    (e: any) => {
      const currentTableName = e.detail?.target?.value?.trim() || '';
      setTableNameValue(currentTableName);
      const errorStatus = !(currentTableName.length > 0);
      if (errorStatus !== tableNameError) {
        setTableNameError(errorStatus);
      }
    },
    [tableNameError]
  );

  const handleSearchInputUpdate = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedText(currentSearchedText);
    }, []
  );

  const clearTableData = useCallback(() => {
    setTableMetrics([]);
    setTableNameValue('');
    setWidgetModalSelector('');
    setSearchedText('');
    setParamsSelected(false);
    setExpandedParameterGroups([]);
    setExpandedBeforeReorder([]);
    setSelectedTabIndex(0);
    setMetricsGroupsOrder([]);
    setUnitsSubcolumnsOrder([]);
    // collapse all accordions
    const allCategoryAccordions = document.querySelectorAll(`tds-accordion-item[id^=category_item]`);
    allCategoryAccordions.forEach((accordionItem: HTMLTdsAccordionItemElement | any) => {
      accordionItem.expanded = false;
    })

    if (tableNameError) {
      setTableNameError(false);
    }
  }, [tableNameError, setWidgetModalSelector]);

  const updateMetricGroupsOrder = useCallback(() => {
    const tempOrderedGroups: SetStateAction<{ columnName: string; subColumns: Array<string>; }[]> = [];
    
    metricsGroupsOrder.forEach(item => {
      const currentMetric: { columnName: string; subColumns: Array<string>; } = tableMetrics[Number(item)];
      const reorderedUnitsForMetric = unitsSubcolumnsOrder.find(metric => metric.columnName.toLowerCase() === currentMetric.columnName.toLowerCase());

      if (reorderedUnitsForMetric?.unitsOrder && reorderedUnitsForMetric.unitsOrder.length > 1) {
        const reorderedUnits: string[] = [];
        reorderedUnitsForMetric.unitsOrder.forEach((unitItemIndex: string) => {
          reorderedUnits.push(currentMetric.subColumns[Number(unitItemIndex)]);
        });
        tempOrderedGroups.push({ columnName: currentMetric.columnName, subColumns: reorderedUnits });
      } else {
        tempOrderedGroups.push(currentMetric);
      }
    });

    setTableMetrics(tempOrderedGroups);
  }, [metricsGroupsOrder, tableMetrics, unitsSubcolumnsOrder]);

  const updateUnitSubcolumnsOrder = (columnName: string, subcolumnsOrder: string[]) => {
    let tempUnitsSubcolOrder: Array<{ columnName: string; unitsOrder: Array<string>; }> = [...unitsSubcolumnsOrder];
    const groupIndex = tempUnitsSubcolOrder.findIndex(metric => metric.columnName.toLowerCase() === columnName.toLowerCase());
    if (groupIndex > -1) {
      tempUnitsSubcolOrder[groupIndex].unitsOrder = subcolumnsOrder;
    }
    setUnitsSubcolumnsOrder(tempUnitsSubcolOrder);
  }

  const updateSelectedTab = useCallback(
    (e: any) => {
      const newSelectedTabIndex = e?.detail?.selectedTabIndex || 0;
      if (newSelectedTabIndex !== selectedTabIndex) {
        setSelectedTabIndex(newSelectedTabIndex);

        if (newSelectedTabIndex === 1) {
          const tempExpandedCategories: number[] = [];
          groupParams.forEach((category: any, categoryIndex: number) => {
            if (isAccordionItemExpanded(`category_item_${categoryIndex}`)) tempExpandedCategories.push(categoryIndex);
          });
          if (JSON.stringify(tempExpandedCategories) !== JSON.stringify(expandedBeforeReorder)) {
            setExpandedBeforeReorder(tempExpandedCategories);
          }
        } else {
          updateMetricGroupsOrder();
        }
      }
  }, [expandedBeforeReorder, selectedTabIndex, updateMetricGroupsOrder]);

  useEffect(() => {
    const nameTextField = tableNameElement.current;
    if (!nameTextField) return;

    nameTextField.addEventListener('tdsInput', handleNameChange);

    return () => {
      nameTextField.removeEventListener('tdsInput', handleNameChange);
    };
  }, [handleNameChange]);

  useEffect(() => {
    const searchTextField = searchInputRef.current;
    if (!searchTextField) return;

    searchTextField.addEventListener('tdsInput', handleSearchInputUpdate);

    return () => {
      searchTextField.removeEventListener('tdsInput', handleSearchInputUpdate);
    };
  }, [handleSearchInputUpdate]);

  useEffect(() => {
    const tableModal = tableModalElement.current;
    tableModal?.addEventListener('tdsClose', clearTableData);
    return () => tableModal?.removeEventListener('tdsClose', clearTableData);
  }, [clearTableData]);

  useEffect(() => {
    const tabsElement = tabsRef.current;
    tabsElement?.addEventListener('tdsChange', updateSelectedTab);
    return () => tabsElement?.removeEventListener('tdsChange', updateSelectedTab);
  }, [updateSelectedTab]);

  useEffect(() => {
    // reset columns order when view metrics get updated
    const tempAscOrder: Array<string> = [];
    const tempUnitsSubcolOrder: Array<{ columnName: string; unitsOrder: Array<string>; }> = [];
    
    tableMetrics.forEach((element, index) => {
      tempAscOrder.push(index.toString());
      tempUnitsSubcolOrder.push({ columnName: element.columnName, unitsOrder: Array.from({length: element.subColumns.length || 0}, (e, i)=> i.toString())})
    });

    setUnitsSubcolumnsOrder(tempUnitsSubcolOrder);
    setMetricsGroupsOrder(tempAscOrder);
  }, [tableMetrics]);

  useEffect(() => {
    if (selector?.length > 0 &&
      selector === 'editTableWidgetButton' && widgetSpec) {
      setTableNameValue(widgetSpec.title?.text || '');
      setTableMetrics(widgetSpec.properties.columns || []);
      setExpandedParameterGroups(getTableParamsExpanded(widgetSpec.properties.columns || [], groupParams))
    }
  }, [ selector, widgetSpec]);

  const updateTableParams = (
    newCheckedStatus: boolean,
    checkboxValue: string,
    checkboxName: string,
    isParentGroup: boolean
  ) => {
    const updatedList = updateParamsList(
      newCheckedStatus,
      checkboxValue,
      checkboxName,
      isParentGroup,
      tableMetrics,
      groupParams
    );

    setTableMetrics(updatedList);
  };

  const markAllCategoryGroups = (categoryIndex: number, newCheckedStatus: boolean) => {
    const allCategoryCheckboxes = document.querySelectorAll(`tds-checkbox[id^=category_${categoryIndex}_checkbox]`);
    let updatedTableMetrics = [...tableMetrics];

    allCategoryCheckboxes.forEach((parentCheckbox: HTMLTdsCheckboxElement | any) => {
      // there is one special case where not all children checkboxes are checked so parent is checked but indeterminate
      // we need to toggle all unchecked children checkboxes
      if (newCheckedStatus && parentCheckbox.checked && parentCheckbox.indeterminate) {
        const allChildrenCheckboxes = document.querySelectorAll(`tds-checkbox[name^=${parentCheckbox.name}__]`);
        allChildrenCheckboxes.forEach((childCheckbox: HTMLTdsCheckboxElement | any) => {
          if (childCheckbox.checked !== newCheckedStatus) {
            childCheckbox.toggleCheckbox();
            updatedTableMetrics = updateParamsList(
              newCheckedStatus,
              childCheckbox.value,
              childCheckbox.name,
              false,
              updatedTableMetrics,
              groupParams
            );
          }
        })
      }
      
      if (parentCheckbox.checked !== newCheckedStatus) {
        parentCheckbox.toggleCheckbox();
        updatedTableMetrics = updateParamsList(
          newCheckedStatus,
          parentCheckbox.value,
          parentCheckbox.name,
          true,
          updatedTableMetrics,
          groupParams
        );
      }
    });

    setTableMetrics(updatedTableMetrics);
  }

  const updateVisibleCategoryGroups = (categoryIndex: number, visibleGroups: number) => {
    if (visibleCategories[categoryIndex] !== visibleGroups) {
      const nextVisibleCategories = visibleCategories.map((categoryCount, index) => {
        return (index === categoryIndex ? visibleGroups : categoryCount);
      });
      setVisibleCategories(nextVisibleCategories);
    }
  }

  const saveTable = async () => {
    const isEditing = selector === 'editTableWidgetButton';
    const tempOrderedGroups: SetStateAction<{ columnName: string; subColumns: Array<string>; }[]> = [];
    
    metricsGroupsOrder.forEach(item => {
      const currentMetric: { columnName: string; subColumns: Array<string>; } = tableMetrics[Number(item)];
      const reorderedUnitsForMetric = unitsSubcolumnsOrder.find(metric => metric.columnName.toLowerCase() === currentMetric.columnName.toLowerCase());

      if (reorderedUnitsForMetric?.unitsOrder && reorderedUnitsForMetric.unitsOrder.length > 1) {
        const reorderedUnits: string[] = [];
        reorderedUnitsForMetric.unitsOrder.forEach((unitItemIndex: string) => {
          reorderedUnits.push(currentMetric.subColumns[Number(unitItemIndex)]);
        });
        tempOrderedGroups.push({ columnName: currentMetric.columnName, subColumns: reorderedUnits });
      } else {
        tempOrderedGroups.push(currentMetric);
      }
    });

    const tableMetricsGroups = Object.keys(tempOrderedGroups);
    const currentTableName = tableNameElement.current?.value?.trim() || '';
    setTableNameError(currentTableName.length === 0);

    if (currentTableName.length > 0 && tableMetricsGroups?.length > 0) {
      const tempPayload = structuredClone(currentDashboard)

      if(isEditing) {
        const editedWidgetIndex = tempPayload.widgets.findIndex(widget => widget.widgetReference === widgetSpec?.widgetReference)

        if(editedWidgetIndex !== -1) {
          tempPayload.widgets.splice(editedWidgetIndex, 1, {
            widgetReference: widgetSpec?.widgetReference,
            title: { text: currentTableName, isTranslationKeyId: false },
            type: 'equipmentUsage',
            view: 'table',
            properties: {
              filters: [],
              columns: tempOrderedGroups,
            },
          })
        }
      } else {
        tempPayload.widgets.push({
          type: 'equipmentUsage',
          title: { text: currentTableName, isTranslationKeyId: false },
          view: 'table',
          properties: {
            filters: [],
            columns: tempOrderedGroups,
          },
        })
      }

      try {
        const result = await updateDashboard(tempPayload).unwrap()
        pushNotification({
          id: uuidv4(),
          header: t('Lyckad'),
          subheader: t('ÄndringarnaHarSparats'),
          variant: 'success',
        });

        tableModalElement.current?.closeModal();
        clearTableData();
        updateSelectedDashboard(result.dashboardReference);          
      } catch (err) {
        pushNotification({
          id: uuidv4(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  };
  useEffect(() => {
  const currentTableModal = tableModalElement.current;
  if (selector.length && currentTableModal) {
    currentTableModal.showModal();
    setHasModalLoaded(true);
  }
}, [selector]);

  return (
    <>
      <TdsModal
        selector={(selector?.length > 0) ? `#${selector}` : ''}
        size={size}
        prevent={true}
        actions-position='sticky'
        ref={tableModalElement}>
        <span slot='header' style={{ height: '24px' }}>{t(header)}</span>
        <span slot='body'>
        <div className={selector === 'createWidgetButton' ||
            (selector === 'editTableWidgetButton' &&
              widgetSpec) ? '' : 'hiddenElement'}>
            <div className={styles.initialRowContainer}>
              <div className={styles.viewNameContainer}>
                <TdsTextField
                  type='text'
                  size='md'
                  ref={tableNameElement}
                  label={t('VP_TableName')}
                  label-position='outside'
                  placeholder={t('Enter table name')} // need a key for it
                  state={tableNameError ? 'error' : 'default'}
                  helper={t('_Obligatorisk')}
                  value={tableNameValue}
                />
              </div>
              <div className={styles.searchContainer}>
                <TdsTextField 
                  type='text'
                  modeVariant='primary'
                  ref={searchInputRef}
                  size='md'
                  state='default'
                  placeholder={t('Sök')}
                  helper=''
                  value={searchedText}
                > 
                  {searchedText?.length > 0 ?
                    <TdsIcon className='clickableElement' slot='suffix' name='cross' size='20px' onClick={() => setSearchedText('')} />
                    :                        
                    <TdsIcon slot='suffix' name='search' size='20px' />
                  }
                </TdsTextField>
              </div>
            </div>
            <div className={styles.metricContainerHeader}>
              <TdsFolderTabs
                modeVariant='secondary'
                ref={tabsRef}
                defaultSelectedIndex={0}
                selectedIndex={selectedTabIndex}
              >
                <TdsFolderTab>
                  <button>{t('VP_SelectMetrics')}</button>
                </TdsFolderTab>
                <TdsFolderTab disabled={tableMetrics?.length === 0 || undefined} id='reorder_metrics_tab'>
                  <button>{t('VP_MetricsReorderTitle')}</button>
                </TdsFolderTab>
                { tableMetrics?.length === 0 && 
                  <TdsTooltip
                    placement='top'
                    selector='#reorder_metrics_tab'
                    text={t('DuMåsteVäljaEttEllerFleraAlternativ_')}
                    className='buttonTooltip'
                  />
                }
              </TdsFolderTabs>
            </div>
            { selectedTabIndex === 0 ?
              <div className={styles.metricsContainer}>
                { visibleCategories.every(categoryCount => categoryCount === 0) && searchedText &&
                  <span className={styles.noParamResults}>{t('SökningenGavIngaResultat')}</span> }
                  <TdsAccordion modeVariant='secondary' hideLastBorder>
                    {groupParams.map((category: { categoryName: string | undefined; groups: {}; }, categoryIndex: number) => {
                      const categoryName = stripNonAlphaCharacters(
                        t(getNameTranslationKey(category.categoryName))
                      );
                      const categoryNameContainsSearchedText = categoryName?.toLowerCase()?.includes(searchedText?.toLowerCase());
                      const categoryContainsSearchedText = searchedText?.length > 0 && (visibleCategories[categoryIndex] > 0 || categoryNameContainsSearchedText);
                      const checkedParamGroups = getGroupsCountPerCategory(
                        Object.keys(category.groups),
                        tableMetrics.map(group => group.columnName)
                      );

                      return <TdsAccordionItem
                        key={`category_${categoryIndex}`}
                        id={`category_item_${categoryIndex}`}
                        paddingReset
                        expanded={categoryContainsSearchedText ||
                                  (categoryIndex === 0 && selector ==='createWidgetButton' && !hasModalLoaded) ||
                                  isAccordionItemExpanded(`category_item_${categoryIndex}`) ||
                                  expandedBeforeReorder.includes(categoryIndex) ||
                                  (!paramsSelected &&
                                    (categoryContainsSearchedText ||
                                    (selector === 'editTableWidgetButton' && checkedParamGroups > 0))
                                  ) || undefined
                                }
                        className={searchedText && visibleCategories[categoryIndex] === 0 && !categoryNameContainsSearchedText ? 'hiddenElement' : undefined}
                        header={`${categoryName} (${checkedParamGroups}/${Object.keys(category.groups).length})`}>
                          <div className={styles.selectButtonsContainer}>
                            <p className={styles.selectLinkContainer}>
                              <TdsLink
                                underline={false}
                                disabled={checkedParamGroups === Object.keys(category.groups).length && allGroupsFullySelected(tableMetrics, category.groups)}
                                onClick={() => markAllCategoryGroups(categoryIndex, true)}>
                                  <span>{t('MarkeraAlla')}</span>
                              </TdsLink>
                            </p>
                            <p>
                              <TdsLink
                                underline={false}
                                disabled={checkedParamGroups === 0}
                                onClick={() => markAllCategoryGroups(categoryIndex, false)}>
                                  <span>{t('AvmarkeraAlla')}</span>
                              </TdsLink>
                            </p>
                          </div>
                          <ParameterGroupsContainer
                            categoryIndex={categoryIndex}
                            categoryNameContainsSearchedText={categoryNameContainsSearchedText}
                            groups={category.groups}
                            propulsionConsumptionUnit={propulsionConsumptionUnit}
                            gasUnit={gasConsumptionUnit}
                            viewMetrics={tableMetrics}
                            expandedParameterGroups={expandedParameterGroups}
                            updateParamsList={updateTableParams}
                            searchedText={searchedText}
                            updateVisibleCategoryGroups={updateVisibleCategoryGroups}
                            setCheckboxMarked={setParamsSelected}
                            updateExpandedParamGroups={setExpandedParameterGroups}
                          />
                      </TdsAccordionItem>
                    })}
                  </TdsAccordion>
              </div>
              :
              <div className={styles.reorderContainer}>
                <div className={styles.metricsContainer}>
                  {tableMetrics?.length > 0 &&
                    <div className={styles.draggableTableContainer}>
                      <TableHeaderReorderContainer
                        selectedMetrics={tableMetrics}
                        propulsionConsumptionUnit={propulsionConsumptionUnit}
                        gasUnit={gasConsumptionUnit}
                        updateMetricGroupsOrder={setMetricsGroupsOrder}
                        updateUnitSubcolumnsOrder={updateUnitSubcolumnsOrder}
                      />
                    </div>
                  }
                  <div className={`${styles.reorderMetricsHeader} ${!tableMetrics?.length && styles.smallerPaddingTop}`}>
                    <TdsIcon name='info' size='20' className={styles.infoIcon} />
                    <span>
                      {tableMetrics?.length > 0 ?
                        t('VP_MetricsReorderBody') :
                        t('VP_SelectMetrics')}
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </span>
        <span slot='actions' className={styles.actionButtons}>
            <div className={styles.rightSideButtons}>
              <TdsButton
                data-dismiss-modal
                size='sm'
                text={t('Avbryt')}
                type='button'
                variant='secondary'
              />
              {isUpdatingDashboard ? (
                <div className={styles.buttonSpinner}>
                  <TdsSpinner size='sm' />
                </div>
              ) : (
                <TdsButton
                  size='sm'
                  text={selector === 'createWidgetButton' ? t('VP_CreateWidget') : t('VP_EditWidget')}
                  type='button'
                  disabled={
                    !(Object.keys(tableMetrics)?.length > 0) ||
                    tableNameError ||
                    (!tableNameError && tableNameValue.length === 0) ||
                    undefined
                  }
                  onClick={saveTable}
                />
              )}
            </div>
        </span>
      </TdsModal>
    </>
  );
};

export default TableWidgetModal;
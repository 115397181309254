import { getSpecificCookie } from './general';

export const tryAdobeLogPageView = (pageName: string, staffData?: StaffDetails) => {
  const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}');
  if (cookiesConsentObj?.performance) {
    window?.adobeDataLayer?.push({
      'event': 'pageView',
      'pageInfo': {
        'name': `vp:${pageName}`,
        'language': staffData?.language || navigator.language
      },
      'userInfo': {
        'language': staffData?.language || '',
        'vehicleIdentifier': staffData?.vehicleIdentifier || '',
        'gasUnit': staffData?.gasConsumptionUnit || '',
        'fuelConsumptionUnit': staffData?.propulsionConsumptionUnit || ''
      },
      'site': 'vehicle performance'
    });
  }
}

export const tryAdobeLogLinkClick = (linkEventName: string = '', parentComponent: string = '') => {
  const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}');
  if (cookiesConsentObj?.performance) {
    window?.adobeDataLayer?.push({
      'event': 'linkClick',
			'linkClicked': linkEventName,
      'parentComponent': parentComponent
    });
  }
}

export const tryAdobeLogExportClick = (
	exportedData: 'driverData' | 'vehicleData' | 'fuelEvents',
	exportType: 'excel' | 'pdf' = 'excel',
	startDate: string = '',
	endDate: string = '') => {
  const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}');
  if (cookiesConsentObj?.performance) {
    window?.adobeDataLayer?.push({
      'event': 'exportData',
			'exportInfo': {
				'exportType': exportType,
				'exportedData': exportedData,
				'startPeriod': startDate,
				'endPeriod': endDate
			}
    });
  }
}

export const tryAdobeLogError = (error: any) => {
  const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent') || '{}');
  if (cookiesConsentObj?.performance) {
    window?.adobeDataLayer?.push({
      'event': 'error',
			'errorInfo': {
				'errorDetails': error,
				'errorPage': window.location.href
			}
    });
  }
}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { uuidv4 } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || 'vp';

export const cloudwatchRumApi = createApi({
  reducerPath: 'cloudwatchRumApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_VP_BFF_HOST}/api/v1/apm`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getApmCredentials: builder.query({
      query: () => ({
        url: '/credentials',
        method: 'GET'
      }),
    }),
  }),
});

export const { useGetApmCredentialsQuery } = cloudwatchRumApi;
import i18n from './localization';
import { unitToTranslationKey } from './report/convertTranslationKeys';
import { stripBraces } from './report/tableUtils';
import { getUnitBasedOnProfileSettings } from './unitsCategories';

export const updateParamsList = (
            newCheckedStatus: boolean,
            checkboxValue: string,
            checkboxName: string,
            isParentGroup: boolean,
            viewMetrics: Array<{
              columnName: string;
              subColumns: Array<string>
            }>,
            groupParams: any): Array<{
              columnName: string;
              subColumns: Array<string>
            }> => {
  
  let tempMetricsGroups = [...viewMetrics];

  if (newCheckedStatus) {
    // adding a param
    if (isParentGroup) {
      let metricsGroupInTempObj = tempMetricsGroups.find(metricGroup => metricGroup.columnName === checkboxValue )
      let metricsGroupExists = typeof metricsGroupInTempObj !== 'undefined'
      // adding the parent group to the list of view params if not already there
      if (!metricsGroupExists) {
        metricsGroupInTempObj = {
          columnName: checkboxValue,
          subColumns: []
        }

        tempMetricsGroups.unshift(metricsGroupInTempObj)
      }
      const singleCategory: any = groupParams.find((element: { categoryName: string, groups: any }) => Object.keys(element.groups).includes(checkboxValue)) || { groups: {}};
      // adding subcolumns of the parent group to the view params list if they are not already there
      singleCategory.groups[checkboxValue]?.forEach((singleMetric: { name: string }) => {
        if (metricsGroupInTempObj && !metricsGroupInTempObj.subColumns.includes(singleMetric.name)) {
          metricsGroupInTempObj.subColumns.push(singleMetric.name);
        }
      });
    } else {
      // first check if parent exists already in the view params list
      const parent = checkboxName.substring(0, checkboxName.indexOf('__'));
      let currentParentGroup = tempMetricsGroups.find(group => group.columnName === parent)
      let currentParentGroupExists = typeof currentParentGroup !== 'undefined'

      // now add the child checkbox checked in the modal to the view params list if not already there
      if (currentParentGroup && !currentParentGroup.subColumns.includes(checkboxValue)) {
        let updatedMetricsGroups = tempMetricsGroups.map((group) => {
          if(group.columnName === parent) {
            return {
              ...group,
              subColumns: [...group.subColumns, checkboxValue]
            }
          }

          return group
        })

        tempMetricsGroups = updatedMetricsGroups
      }

      if(!currentParentGroupExists) {
        tempMetricsGroups.unshift({ columnName: parent, subColumns: [checkboxValue]})
      }
    }
  } else {
    // removing a param
    if (isParentGroup) {
      // need to remove an entire parent group with all its children checkboxes
      tempMetricsGroups = tempMetricsGroups.filter(group => group.columnName !== checkboxValue)
    } else {
      // need to remove the child checkbox that was unchecked in the modal
      const parent = checkboxName.substring(0, checkboxName.indexOf('__'));

      const updatedGroups = tempMetricsGroups.map((group) => {
        if(group.columnName === parent) {
          return {
            ...group,
            subColumns: group.subColumns.filter(col => col !== checkboxValue)
          }
        }

        return group;
      })

      tempMetricsGroups = updatedGroups.filter(group => group.subColumns.length > 0)
    }
  }

  return tempMetricsGroups;
}

export const getGroupsCountPerCategory = (categoryGroupOfMetrics: string[], currentViewMetricsGroups: string[]) => {
  let count = 0;
  categoryGroupOfMetrics.forEach(singleGroup => {
    if (currentViewMetricsGroups.includes(singleGroup)) {
      count++;
    }
  });

  return count;
}

export const allGroupsFullySelected = (viewMetrics: { columnName: string; subColumns: string[]; }[], groups: any) => {
  const categoryGroups = Object.keys(groups);
  const allParamsChecked = (groupIdentifier: string) => {
    const selectedParamsUnderGroup = viewMetrics.find(group => group.columnName.toLowerCase() === groupIdentifier.toLowerCase());
    return selectedParamsUnderGroup?.subColumns.length === groups[groupIdentifier].length;
  }

  return categoryGroups.every(allParamsChecked);
}

export const anyUnitContainsSearchedText = (
        searchedText: string = '',
        units: Array<{name: string, unitType: string}> = [],
        propulsionConsumptionUnit: string,
        gasUnit: string) => {

  const firstOccurence = units.findIndex(element => stripBraces(
                          i18n.t(unitToTranslationKey(
                            getUnitBasedOnProfileSettings(element, propulsionConsumptionUnit, gasUnit)
                        )))?.toLowerCase()?.includes(searchedText.toLowerCase()));
  
  return firstOccurence >= 0;
}

export const isAccordionItemExpanded = (accordionItemId: string) => {
  const element: HTMLTdsAccordionItemElement | null = document.querySelector(`#${accordionItemId}`);
  return element?.expanded || false;
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsIcon,
  TdsMessage,
  TdsSpinner,
  TdsTextField,
  TdsTooltip,
} from '@scania/tegel-react';

import '../../styles/GeneralStyles.css';
import style from '../../styles/FuelReportTable.module.css';
import styles from '../../styles/DataTableHeader.module.css';

import FuelReportExportButton from './FuelReportExportButton';
import { useGetFuelReportQuery } from '../../state/fuelReport/query';
import { adjustOdometerToProfileSetting, getAvailableIdentifier, sortFuelTableData } from '../../utils/report/tableUtils';
import { refuelingEventToTranslationKey } from '../../utils/report/convertTranslationKeys';
import { localDate, truncateText } from '../../utils/general';

interface FuelReportTableProps {
  selectedGroup?: string;
  vehicleIdentifier: 'registrationNumber' | 'chassisNumber' | 'alias';
  propulsionConsumptionUnit: string;
  startDate: string;
  endDate: string;
  staffDetails: StaffDetails;
}

const filterGroup = (item: FuelReportItem, group: string = 'groupFilterAll') => {
  if (group === 'groupFilterNoAffiliation') {
    return !item.equipment?.externalEquipmentGroupReference || item.equipment.externalEquipmentGroupReference?.length === 0;
  }

  return group === 'groupFilterAll' || item.equipment.externalEquipmentGroupReference.includes(group);
}

const FuelReportTable = ({ selectedGroup, vehicleIdentifier, propulsionConsumptionUnit, startDate, endDate, staffDetails }: FuelReportTableProps) => {
  const { t } = useTranslation();
  const fuelTableHeaderTooltipLimitations = [
    {
      text: t('Utrustning'),
      limit: 20
    },
    {
      text: t('Tid_Tankning_'),
      limit: 27
    },
    {
      text: t('Vägmätarställning'),
      limit: 13
    },
    {
      text: t('Händelse'),
      limit: 12
    },
    {
      text: t('Förändringar'),
      limit: 17
    },
    {
      text: t('Ökning'),
      limit: 12
    },
    {
      text: t('Förare'),
      limit: 27
    },
    {
      text: t('Adress'),
      limit: 47
    },
  ];

  const {
    data: serverData,
    isLoading: isFuelEventsLoading,
    isSuccess: fuelEventsLoadedSuccessfully,
    isError: isErrorLoadingFuelEvents,
    isFetching: isFuelEventsFetching,
  } = useGetFuelReportQuery({startTime: startDate, endTime: endDate, language: staffDetails.language});

  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filteredData, setFilteredData] = useState<FuelReportItem[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const searchElementRef = useRef<HTMLTdsTextFieldElement>(null);


  useEffect(() => {
    const freeTextFilter = (item: FuelReportItem) => {
      const { alias, registrationNumber, chassisNumber } = item.equipment;
    
      const searchableFields = [
      alias,
      registrationNumber,
      chassisNumber,
      adjustOdometerToProfileSetting(item.odometerInMeters, propulsionConsumptionUnit).toLocaleString(),
      localDate(item.eventTime),
      `${item.oldLevel} %`,
      `${item.newLevel} %`,
      `${item.increaseInPercentage} %`,
      t(refuelingEventToTranslationKey(item.refuelType)),
      (item.driver?.name || t('IngetFörar_Id')),
      ].filter(Boolean);
    
      const lowerCaseFilter = searchedText.trim().toLocaleLowerCase();
    
      const matches = searchableFields.some((field) => {
      const fieldValue = field?.toString().toLocaleLowerCase();
      return fieldValue?.includes(lowerCaseFilter);
      });
    
      return matches;
    };
  
    if (serverData) {
      const newFilteredData = serverData
        .filter((r: FuelReportItem) => filterGroup(r, selectedGroup))
        .filter(freeTextFilter);
  
      setFilteredData(
        sortFuelTableData(sortField, sortDirection, vehicleIdentifier, newFilteredData)
      );
    }
  }, [serverData, selectedGroup, searchedText, vehicleIdentifier, propulsionConsumptionUnit, sortDirection, sortField, t]);

  const handleSearchForText = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedText(currentSearchedText);
    }, []
  );

  useEffect(() => {
    if (staffDetails && serverData && !isFuelEventsFetching && !isFuelEventsLoading) {
      const searchTextField = searchElementRef.current;
      if (!searchTextField) return;

      searchTextField.addEventListener('tdsInput', handleSearchForText);

      return () => {
        searchTextField.removeEventListener('tdsInput', handleSearchForText);
      };
    }
  }, [handleSearchForText, serverData, isFuelEventsFetching, isFuelEventsLoading, staffDetails]);
  
  const sortByColumn = (columnToBeSorted: string) => {
    if (columnToBeSorted && filteredData) {
      const newSortingDirection = columnToBeSorted.toLowerCase() === sortField.toLowerCase() ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
      setSortField(columnToBeSorted);
      setSortDirection(newSortingDirection);
      setFilteredData(sortFuelTableData(columnToBeSorted, newSortingDirection, vehicleIdentifier, filteredData));
    }
  };

  return (
    <>
      {(isFuelEventsFetching || isFuelEventsLoading) && <div className='spinnerContainer'><TdsSpinner size='md' /></div>}
      { isErrorLoadingFuelEvents && (
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      )}
      {!isFuelEventsFetching && !isFuelEventsLoading && fuelEventsLoadedSuccessfully && filteredData &&
        <>
          <div className={style.tableActionsContainer}>
            <div className={style.searchField}>
              <TdsTextField
                type='text'
                size='sm'
                ref={searchElementRef}
                placeholder={t('Sök')}
                value={searchedText}
                modeVariant='primary'
                disabled={isErrorLoadingFuelEvents ||
                  (filteredData && filteredData?.length === 0 && !searchedText?.length)
                }
              >
                {searchedText?.length > 0 && serverData?.length > 0 ?
                  <TdsIcon className='clickableElement' slot='suffix' name='cross' size='20px' onClick={() => { setSearchedText(''); }} />
                  :
                  <TdsIcon slot='suffix' name='search' size='20px' />
                }
              </TdsTextField>
            </div>
            <FuelReportExportButton
              buttonText={t('ExporteraTillExcel')}
              buttonType='primary'
              disabled={!filteredData?.length}
              sheetTitle={t('Rapport')}
              tableData={filteredData}
              staffDetails={staffDetails}
              interval={{
                startDate,
                endDate,
              }}
            />
          </div>

          <div className={style.tableContainer}>
            <table className='tds-table--responsive fullWidth'>
              <thead>
                <tr>
                  <th
                    id='fuelTableHeader1'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam}
                      ${styles.firstColumnZIndex} ${style.stickyLeftCol} ${style.firstTableCol} ${!(sortField === 'equipment') && styles.hoverableHeader}
                      ${styles.noLeftPadding} ${sortField === 'equipment' ? styles.sortedColumn : ''} ${styles.hoverable}`}
                    onClick={() => sortByColumn('equipment')}>
                    <div className={styles.thContent}>
                      <span>{t('Utrustning')}</span>
                      <span className={`${sortField.toLowerCase() !== 'equipment' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'equipment' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader2'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam} ${style.wideTableCol}
                      ${sortField === 'time' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('time')}>
                    <div className={style.thContent}>
                      <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Tid_Tankning_')}</div>
                      <span className={`${sortField.toLowerCase() !== 'time' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'time' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader3'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam} ${style.mediumTableCol}
                      ${sortField === 'odometer' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('odometer')}>
                    <div className={style.thContent}>
                    <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Vägmätarställning')}</div>
                      <span className={`${sortField.toLowerCase() !== 'odometer' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'odometer' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader4'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam} ${style.mediumTableCol}
                      ${sortField === 'event' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('event')}>
                    <div className={style.thContent}>
                    <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Händelse')}</div>
                      <span className={`${sortField.toLowerCase() !== 'event' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'event' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th className={`${styles.firstRowHeaderCell} leftAlignedText ${styles.stickyTopHeaderParam} ${style.mediumTableCol}`} id='fuelTableHeader5'>
                    <div className={`${style.thContent} fullWidth`}>
                      <div>{t('Förändringar')}</div>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader6'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam} ${style.mediumTableCol}
                      ${sortField === 'increase' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('increase')}>
                    <div className={style.thContent}>
                    <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Ökning')}</div>
                      <span className={`${sortField.toLowerCase() !== 'increase' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'increase' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader7'
                    className={`clickableElement leftAlignedText ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam} ${style.wideTableCol}
                      ${sortField === 'driver' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('driver')}>
                    <div className={style.thContent}>
                    <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Förare')}</div>
                      <span className={`${sortField.toLowerCase() !== 'driver' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'driver' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                  <th
                    id='fuelTableHeader8'
                    className={`clickableElement leftAlignedText ${style.wideTableCol} ${styles.firstRowHeaderCell} ${styles.stickyTopHeaderParam}
                      ${sortField === 'address' ? styles.sortedColumn : styles.hoverableHeader} ${styles.hoverable}`}
                    onClick={() => sortByColumn('address')}>
                    <div className={style.thContent}>
                    <div className={`${styles.overflowTh} ${style.elypticText}`}>{t('Adress')}</div>
                      <span className={`${sortField.toLowerCase() !== 'address' ? styles.beforeIcons : styles.iconsContainer}`}>
                        { sortField.toLowerCase() !== 'address' ?
                          <TdsIcon name='sorting' /> :
                          <TdsIcon name={sortDirection === 'desc' ? 'arrow_down' : 'arrow_up'} />
                        }
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              { filteredData?.length === 0 ?
                  <tbody>
                    <tr>
                      <td className={`${style.stickyLeftCol} ${style.firstTableCol} tds-u-p2`} colSpan={3}>
                        <TdsMessage variant='information' minimal header={t('IngenDataAttVisa')}/>
                      </td>
                      <td colSpan={5}></td>
                    </tr>
                  </tbody>
                  :              
                  <tbody>
                    {filteredData.map((row, index) => {
                      const { alias, chassisNumber, registrationNumber } = row.equipment;
                      const equipment = getAvailableIdentifier(vehicleIdentifier, { alias, chassisNumber, registrationNumber });

                      return (
                        <tr key={`fuel_event_${index}`} className={`${style.tableRow} ${index % 2 === 0 ? style.evenRow : style.oddRow}`}>
                          <td className={`leftAlignedText ${style.stickyLeftCol} ${index % 2 === 0 ? style.evenRow : style.oddRow}`}>{equipment}</td>
                          <td>{row.eventTime && localDate(row.eventTime)}</td>
                          <td>{adjustOdometerToProfileSetting(row.odometerInMeters, propulsionConsumptionUnit).toLocaleString() } { propulsionConsumptionUnit.includes('miles') ? t('Miles'): t('Km') }</td>
                          <td>{t(refuelingEventToTranslationKey(row.refuelType))}</td>
                          <td>{`${row.oldLevel} % `}<TdsIcon name='arrow_right' size='10px'  />{` ${row.newLevel} %`}</td>
                          <td>{`${row.increaseInPercentage} %`}</td>
                          <td>{row.driver?.name || t('IngetFörar_Id')}</td>
                          <td>{row.address}</td>
                        </tr>
                      )})
                    }
                  </tbody>
              }
            </table>
            { fuelTableHeaderTooltipLimitations.map((thElement: { text: string; limit: number; }, index: number) => truncateText(thElement.text, thElement.limit).includes('...') &&
              <TdsTooltip
                  placement='bottom-start'
                  text={thElement.text}
                  selector={`#fuelTableHeader${index+1}`}
                  trigger='hover'
                  key={`tooltip_for_th_${index}`}
              />
            )}
          </div>
        </>
      }
    </>
    );
};

export default FuelReportTable;